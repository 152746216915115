import * as React from "react"
import { graphql, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { isMobile } from 'react-device-detect';
import Layout from "../components/layout"
import * as styles from "../components/index.module.css"
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { 
  Button, Paper, TextField, Checkbox, FormControlLabel, useMediaQuery, 
  Slide, Typography
} from '@material-ui/core';

import { connect } from 'react-redux';
import * as uiActions from '../redux/actions/uiActions';
import * as loggingActions from '../redux/actions/loggingActions';
import * as sessionActions from '../redux/actions/sessionActions';
import Api from '../lib/api';
import Helper from '../lib/helper';
import UserHelper from "../lib/userHelper";
import UIHelper from "../lib/uiHelper";
import useStyles from '../assets/style/loginStyle';
import useCommonStyle from '../assets/style/commonStyle';

const pwdValidationInitState = {
  userName: {error: false, touched: false, message: ''}
};

const signUpValidationInitState = {
  email: {error: false, touched: false, message: ''}
};

const isBrowser = typeof window !== 'undefined';

const IndexPage = (props) => {
  const {t} = useTranslation('login');
  const {changeLanguage} = useI18next();
  const [busy, setBusy] = React.useState(false);
  const [showForgetPwd, setShowForgetPwd] = React.useState(false);
  const [pwdResetSent, setPwdResetSent] = React.useState(false);
  const [pwdResetError, setPwdResetError] = React.useState('');
  const [showSignUp, setShowSignUp] = React.useState(false);
  const [signUpSent, setSignUpSent] = React.useState(false);
  const [signUpError, setSignUpError] = React.useState('');
  const [otpRequired, setOtpRequired] = React.useState(false);
  const [otpInvalid, setOtpInvalid] = React.useState(false);
  const locale = props.ui.get('lang');
  const [loginFailed, setLoginFailed] = React.useState(false);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const displayMode = props.ui.get('displayMode');
  const displayModeToUse = displayMode === 'auto' ? (prefersDarkMode ? 'dark' : 'light') : displayMode;
  const classes = useStyles(displayModeToUse);
  const commonClasses = useCommonStyle();
  
  const loginFormRef = React.useRef(null);
  const [loginValidation, setLoginValidation] = React.useState({
    userName: {error: false, touched: false, message: ''},
    password: {error: false, touched: false, message: ''},
    rememberMe: {error: false, touched: false, message: ''},
    otp: {error: false, touched: false, message: ''}
  });
  const loginFields = [
    {field: 'userName', required: true, regExp: UIHelper.regExp.email, type: 'string', label: 'field.username'},
    {field: 'password', required: true, regExp: '', type: 'string', label: 'field.password'},
    {field: 'otp', required: otpRequired, regExp: '', type: 'string', label: 'field.otp'}
  ];

  const pwdFormRef = React.useRef(null);
  const [pwdValidation, setPwdValidation] = React.useState(pwdValidationInitState);
  const pwdFields = [
    {field: 'userName', required: true, regExp: UIHelper.regExp.email, type: 'string', label: 'field.username'}
  ];
  
  const signUpFormRef = React.useRef(null);
  const [signUpValidation, setSignUpValidation] = React.useState(signUpValidationInitState);
  const signUpFields = [
    {field: 'email', required: true, regExp: UIHelper.regExp.email, type: 'string', label: 'field.email'}
  ];

  const setting = Helper.getStateAsJSObj(props.system, 'setting', null);
  const sysConfig = Helper.carefullyGetValue(setting, 'data.configs'.split('.'), []);
  const appDetail = Helper.carefullyGetValue(setting, 'data.application'.split('.'), null);
  const logConfig = sysConfig.find(item => item.category === 'system' && item.identifier === 'system.logging');
  const logEnabled = Helper.carefullyGetValue(logConfig, 'value.log_enable'.split('.'), false);
  const consoleLogEnabled = Helper.carefullyGetValue(logConfig, 'value.console_log_enable'.split('.'), false);

  var allowRememberMe = false;
  var allowUserSignUp = false;
  var userSignUpRole = '';
  if(Helper.arrayHasItem(sysConfig)) {
    let loginPersistency = sysConfig.find(item => item.category === 'authentication' && item.identifier === 'login.persistency');
    if(Helper.isNotNullAndUndefined(loginPersistency?.value?.allow_rememberme)) {
      allowRememberMe = loginPersistency.value.allow_rememberme;
    }

    let loginSecurity = sysConfig.find(item => item.category === 'authentication' && item.identifier === 'login.security');
    if(Helper.isNotNullAndUndefined(loginSecurity?.value?.allow_user_signup)) {
      allowUserSignUp = loginSecurity.value.allow_user_signup;
    }

    if(Helper.stringHasValue(loginSecurity?.value?.user_signup_role)) {
      userSignUpRole = loginSecurity.value.user_signup_role;
    }
  }

  // Use to enable the browser push notification feature
  React.useEffect(() => {
    EnableNotification();
  }, []);

  // Use to register the keydown event
  React.useEffect(() => {
    const onKeyDown = (event) => {
      let key = Helper.carefullyGetValue(event, ['key'], '');
      switch (key.toLowerCase()) {
        case 'escape':
          if(showForgetPwd) {
            showPwdResetScreen(false);
          }

          if(showSignUp) {
            showSignUpScreen(false);
          }

          event.preventDefault();
          break;

        default:
          break;
      }
      
    };

    if(isBrowser) {
      document.addEventListener("keydown", onKeyDown);
    }

    return () => {
      if(isBrowser) {
        document.removeEventListener("keydown", onKeyDown);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForgetPwd, showSignUp]); 


  const EnableNotification = async () => {
    // Get the service worker
    let sw = await navigator.serviceWorker.ready;
    // Try to get the push notification subscription
    let sub = await sw.pushManager.getSubscription();
    
    if(!Helper.isNotNullAndUndefined(sub)) {
      // If on existing subscription, then call the sw.pushManager.subscribe to
      // create a new subscription.
      let newSub = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: process.env.VAPIDKEY_PUBLIC
      });

      // Update the server with the new subscription.
      Api.webPushReg({app_id: process.env.APP_ID, subscription: newSub});
    }
    else {
      // Update the server with the existing subscription.
      Api.webPushReg({app_id: process.env.APP_ID, subscription: sub});
    }
  }

  const authenticate = () => {
    setLoginFailed(false);
    setOtpInvalid(false);
    setBusy(false);
    let valid = Helper.validateFormRef(loginFormRef, loginFields, loginValidation, setLoginValidation, t);
    let credential = {
      email: Helper.carefullyGetValue(loginFormRef, 'current.elements.userName.value'.split('.'), ''),
      password: Helper.carefullyGetValue(loginFormRef, 'current.elements.password.value'.split('.'), '')
    };

    if(otpRequired) {
      credential.otp = Helper.carefullyGetValue(loginFormRef, 'current.elements.otp.value'.split('.'), '');
    }

    if(valid) {
      setBusy(true);
      let rememberMe = Helper.carefullyGetValue(loginFormRef, 'current.elements.rememberMe.checked'.split('.'), false);
      props.dispatch(
        sessionActions.authenticate(credential, rememberMe, (success, authResult) => {
          if(!success) {
            setBusy(false);
            let resErr = Helper.getAxiosError(authResult);
            if(resErr.error === 'INVALID_OTP') {
              if(otpRequired) {
                // If otpRequired is true, this means that authenticate is invoked for 2nd time
                // and still get INVALID_OTP error, this time we need to set the otpInvalid state
                // to true to show the invalid otp error to end user
                setOtpInvalid(true);
              }

              setOtpRequired(true);
            }

            if(resErr.error === 'INVALID_CREDENTIALS') {
              setLoginFailed(true);
            }
          }
          else {
            // Login success, get the user profile
            props.dispatch(
              sessionActions.profileGet((success, result) => {
                setBusy(false);

                if(!success) {
                  if(logEnabled) {
                    result.message += ' - sessionActions.profileGet Error @ /index.js';
                    props.dispatch(
                      loggingActions.loggingNew(
                        Helper.populateLoggingDetail(result, {
                          session: props.session.toJS()
                        })
                      )
                    );
                  }
                  consoleLogEnabled && console.error('sessionActions.profileGet (Landing Page) Error: ', result);
                }

                if(success && Helper.isNotNullAndUndefined(result)) {
                  if(Helper.stringHasValue(result.language) && result.language !== locale) {
                    props.dispatch(
                      uiActions.setLanguage(result.language)
                    );
                    changeLanguage(result.language);
                  }

                  if(Helper.stringHasValue(result.theme) && result.theme !== displayModeToUse) {
                    props.dispatch(
                      uiActions.setDisplayMode(result.theme)
                    );
                  }

                }

                // Determine the initial route after login for the user depend on the user role
                let route = UserHelper.getHomeRouteByRole(setting, authResult.role);
                if(route.length > 0)
                  navigate(route);
              })
            );
          }
        })
      );
    }
  }

  const passwordReset = () => {
    let valid = Helper.validateFormRef(pwdFormRef, pwdFields, pwdValidation, setPwdValidation, t);
    setPwdResetSent(false);
    setPwdResetError('');
    setBusy(false);
    let resetURL = Helper.carefullyGetValue(appDetail, ['url'], '');
    if(valid && Helper.stringHasValue(resetURL)) {
      setBusy(true);
      Api.passwordResetRequest(Helper.carefullyGetValue(pwdFormRef, 'current.elements.userName.value'.split('.'), ''), `${resetURL}pwdreset`)
      .then(_ => {
        setPwdResetSent(true);
        setBusy(false);
      })
      .catch(err => {
        setPwdResetError(err.message);
        setBusy(false);
      });
    }
  }

  const showPwdResetScreen = (show) => {
    setShowForgetPwd(show);
    setPwdResetSent(false);
    setPwdResetError('');
    pwdFormRef?.current && pwdFormRef.current.reset();
    setPwdValidation(pwdValidationInitState);
  }

  const userSignUp = () => {
    let valid = Helper.validateFormRef(signUpFormRef, signUpFields, signUpValidation, setSignUpValidation, t);
    setSignUpSent(false);
    setSignUpError('');
    setBusy(false);
    let signUpURL = Helper.carefullyGetValue(appDetail, ['url'], '');
    if(valid && Helper.stringHasValue(signUpURL)) {
      setBusy(true);
      Api.userInvite(Helper.carefullyGetValue(signUpFormRef, 'current.elements.email.value'.split('.'), ''), userSignUpRole, `${signUpURL}acceptinvite`)
      .then(_ => {
        setSignUpSent(true);
        setBusy(false);
      })
      .catch(err => {
        if(Helper.arrayHasItem(err.errors) && err.errors.filter(item => item.extensions?.code === 'RECORD_NOT_UNIQUE').length > 0) {
          setSignUpError(t('error.signup.userexisted'));
        }
        else {
          setSignUpError(err.message);
        }
        setBusy(false);
      });
    }
  }

  const showSignUpScreen = (show) => {
    setShowSignUp(show);
    setSignUpSent(false);
    setSignUpError('');
    signUpFormRef?.current && signUpFormRef.current.reset();
    setSignUpValidation(signUpValidationInitState);
  }

  return (
    <Layout showMenu={false} showAccessDenied={false} redirectIfAuth={true} busy={busy} backgroundImage={'/login_bg.jpg'} 
      childrenStyle={{
        flexDirection: 'row',
        justifyContent: isMobile ? 'center' : 'right',
        alignItems: 'center',
        padding: 0
      }}
    >
      {/* Forget Password Form */}
      <Slide direction={showForgetPwd ? 'left' : 'right'} in={showForgetPwd} mountOnEnter unmountOnExit style={{ transitionDelay: showForgetPwd ? '300ms' : '0ms' }}>
        <Paper variant={'elevation'} elevation={4} className={classes.loginBox}>
          <div className={styles.textCenter}>
            <StaticImage
              src="../images/app_logo.png"
              loading="eager"
              width={300}
              quality={95}
              formats={["auto"]}
              alt=""
              style={{ marginBottom: `var(--space-3)` }}
            />
            <h1>
              {t(!pwdResetSent ? 'prompt.forgetpassword' : 'prompt.forgetpassword.sent')}
            </h1>
          </div>
          {
            !pwdResetSent &&
            <form ref={pwdFormRef} onSubmit={(event) => {
                passwordReset();
                event.preventDefault();
              }}
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <TextField id="userName" label={t('field.username')} 
                style={{marginBottom: 10}}
                variant="outlined" 
                autoComplete='username'
                error={pwdValidation.userName.error}
                helperText={pwdValidation.userName.message}
                onBlur={(event) => Helper.validateFormField('userName', event.target.value, pwdFields, pwdValidation, setPwdValidation, t)}
              />
              {
                Helper.stringHasValue(pwdResetError) &&
                <Typography variant='subtitle1' color="error" className={classes.title}>
                  {pwdResetError}
                </Typography>
              }
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                <Button variant={'contained'} style={{marginBottom: 10, minWidth: 120}} onClick={_ => showPwdResetScreen(false)}>{t('button.cancel')}</Button>
                <Button variant={'contained'} type='submit' color={'primary'} style={{marginBottom: 10, minWidth: 120}}>{t('button.submit')}</Button>
              </div>
            </form>
          }
          {
            pwdResetSent &&
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant='subtitle1' style={{marginBottom: 20}}>
                {t('forgetpassword.sent.desc')}
              </Typography>
              <Button variant={'contained'} color={'primary'} onClick={_ => showPwdResetScreen(false)}>{t('button.login')}</Button>
            </div>
          }
        </Paper>
      </Slide>

      {/* Sign Up Form */}
      <Slide direction={showSignUp ? 'left' : 'right'} in={showSignUp} mountOnEnter unmountOnExit style={{ transitionDelay: showSignUp ? '300ms' : '0ms' }}>
        <Paper variant={'elevation'} elevation={4} className={classes.loginBox}>
          <div className={styles.textCenter}>
            <StaticImage
              src="../images/app_logo.png"
              loading="eager"
              width={300}
              quality={95}
              formats={["auto"]}
              alt=""
              style={{ marginBottom: `var(--space-3)` }}
            />
            <h1>
              {t(!signUpSent ? 'prompt.signup' : 'prompt.signup.sent')}
            </h1>
          </div>
          {
            !signUpSent &&
            <form ref={signUpFormRef} onSubmit={(event) => {
                userSignUp();
                event.preventDefault();
              }}
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <TextField id="email" label={t('field.email')} 
                style={{marginBottom: 10}}
                variant="outlined" 
                autoComplete='username'
                error={signUpValidation.email.error}
                helperText={signUpValidation.email.message}
                onBlur={(event) => Helper.validateFormField('email', event.target.value, signUpFields, signUpValidation, setSignUpValidation, t)}
              />
              {
                Helper.stringHasValue(signUpError) &&
                <Typography variant='subtitle1' color="error" className={classes.title}>
                  {signUpError}
                </Typography>
              }
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                <Button variant={'contained'} style={{marginBottom: 10, minWidth: 120}} onClick={_ => showSignUpScreen(false)}>{t('button.cancel')}</Button>
                <Button variant={'contained'} type='submit' color={'primary'} style={{marginBottom: 10, minWidth: 120}}>{t('button.submit')}</Button>
              </div>
            </form>
          }
          {
            signUpSent &&
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant='subtitle1' style={{marginBottom: 20}}>
                {t('signup.sent.desc')}
              </Typography>
              <Button variant={'contained'} color={'primary'} onClick={_ => showSignUpScreen(false)}>{t('button.login')}</Button>
            </div>
          }
        </Paper>
      </Slide>

      {/* Login Form */}
      <Slide direction={(!showForgetPwd && !showSignUp) ? 'left' : 'right'} in={!showForgetPwd && !showSignUp} mountOnEnter unmountOnExit>
        <Paper variant='elevation' elevation={4} className={classes.loginBox}>
          <div className={styles.textCenter}>
            <StaticImage
              src="../images/app_logo.png"
              loading="eager"
              width={300}
              quality={95}
              formats={["auto"]}
              alt=""
              style={{ marginBottom: `var(--space-3)` }}
            />
            <h1>
              {t('prompt.login')}
            </h1>
          </div>
          <form ref={loginFormRef} onSubmit={(event) => {
              authenticate();
              event.preventDefault();
            }}
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <TextField id="userName" label={t('field.username')} 
              style={{marginBottom: 10}}
              variant="outlined" 
              autoComplete='username'
              error={loginValidation.userName.error}
              helperText={loginValidation.userName.message}
              onBlur={(event) => Helper.validateFormField('userName', event.target.value, loginFields, loginValidation, setLoginValidation, t)}
            />
            <TextField id="password" label={t('field.password')} 
              style={{marginBottom: 10}}
              variant="outlined" 
              autoComplete='current-password'
              type={'password'}
              error={loginValidation.password.error}
              helperText={loginValidation.password.message}
              onBlur={(event) => Helper.validateFormField('password', event.target.value, loginFields, loginValidation, setLoginValidation, t)}
            />
            {
              otpRequired &&
              <TextField id="otp" label={t('field.otp')} 
                style={{marginBottom: 10}}
                autoFocus
                variant="outlined" 
                type='number'
                error={loginValidation.otp.error}
                helperText={loginValidation.otp.message}
                onBlur={(event) => Helper.validateFormField('otp', event.target.value, loginFields, loginValidation, setLoginValidation, t)}
              />
            }
            {
              loginFailed &&
              <Typography variant='subtitle1' color="error" className={classes.title}>
                {t('error.login.failed')}
              </Typography>
            }
            {
              otpInvalid &&
              <Typography variant='subtitle1' color="error" className={classes.title}>
                {t('error.login.invalidotp')}
              </Typography>
            }
            {
              allowRememberMe &&
              <FormControlLabel
                control={
                  <Checkbox id="rememberMe" />
                }
                label={t('field.rememberme')}
              />
            }
            <Button variant={'contained'} color={'primary'} type='submit' style={{marginBottom: 10}}>{t('button.login')}</Button>
            <div className={commonClasses.flexRow} style={{justifyContent: 'space-evenly'}}>
              <Button variant={'text'} color={'secondary'} style={{marginBottom: 10}} onClick={_ => showPwdResetScreen(true)}>{t('button.forgetpassword')}</Button>
              {
                allowUserSignUp && Helper.stringHasValue(userSignUpRole) &&
                <Button variant={'text'} color={'secondary'} style={{marginBottom: 10}} onClick={_ => showSignUpScreen(true)}>{t('button.signup')}</Button>
              }
            </div>
          </form> 
          
        </Paper>
      </Slide>
    </Layout>
  );
}

export default connect((state) => {
  return {
    session: state.session,
    system: state.system,
    ui: state.ui
  };
})(IndexPage);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
